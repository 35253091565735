import React from "react"

const Icon = ({ name, height='10px', color='white' }) => {
  const width = height
  if (name === 'delete') {
    return (
      <svg style={{height, width }} height="365pt" viewBox="0 0 365.71733 365" width="365pt" xmlns="http://www.w3.org/2000/svg">
        <g fill={color || "#f44336"} >
          <path d="m356.339844 296.347656-286.613282-286.613281c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503906-12.5 32.769532 0 45.25l286.613281 286.613282c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082032c12.523438-12.480468 12.523438-32.75.019532-45.25zm0 0"/><path d="m295.988281 9.734375-286.613281 286.613281c-12.5 12.5-12.5 32.769532 0 45.25l15.082031 15.082032c12.503907 12.5 32.769531 12.5 45.25 0l286.632813-286.59375c12.503906-12.5 12.503906-32.765626 0-45.246094l-15.082032-15.082032c-12.5-12.523437-32.765624-12.523437-45.269531-.023437zm0 0"/>
        </g>
      </svg>
    )
  }

  if (name === 'up') {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 492.002 492.002" style={{height, width, enableBackground:"new 0 0 492.002 492.002"}} xmlSpace="preserve">
        <g fill={color || 'white'} >
          <g>
            <path d="M484.136,328.473L264.988,109.329c-5.064-5.064-11.816-7.844-19.172-7.844c-7.208,0-13.964,2.78-19.02,7.844
              L7.852,328.265C2.788,333.333,0,340.089,0,347.297c0,7.208,2.784,13.968,7.852,19.032l16.124,16.124
              c5.064,5.064,11.824,7.86,19.032,7.86s13.964-2.796,19.032-7.86l183.852-183.852l184.056,184.064
              c5.064,5.06,11.82,7.852,19.032,7.852c7.208,0,13.96-2.792,19.028-7.852l16.128-16.132
              C494.624,356.041,494.624,338.965,484.136,328.473z"/>
          </g>
        </g>
      </svg>
    )
  }

  if (name === 'down') {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 491.996 491.996" style={{height, width, enableBackground: "new 0 0 491.996 491.996"}} xmlSpace="preserve">
        <g fill={color || "#f44336"}>
          <g>
            <path d="M484.132,124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86c-7.208,0-13.964,2.792-19.036,7.86l-183.84,183.848
              L62.056,108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968,2.788-19.036,7.856l-16.12,16.128
              c-10.496,10.488-10.496,27.572,0,38.06l219.136,219.924c5.064,5.064,11.812,8.632,19.084,8.632h0.084
              c7.212,0,13.96-3.572,19.024-8.632l218.932-219.328c5.072-5.064,7.856-12.016,7.864-19.224
              C491.996,136.902,489.204,130.046,484.132,124.986z"/>
          </g>
        </g>
      </svg>
    )
  }

  if (name === 'add') {
    return (
      <svg style={{ height, width }} height="448pt" viewBox="0 0 448 448" width="448pt" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0"/>
      </svg>
    )
  }

  if (name === 'edit') {
    return (
      /*<?xml version="1.0" encoding="iso-8859-1"?>*/
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 383.947 383.947" style={{height: height, enableBackground: 'new 0 0 383.947 383.947'}} xmlSpace="preserve">
        <g fill={color || "white"}>
          <g>
            <g>
              <polygon points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893      "/>
              <path d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04
                C386.027,77.92,386.027,64.373,377.707,56.053z"/>
            </g>
          </g>
        </g>
        <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
        <g></g><g></g><g></g><g></g><g></g><g></g>
      </svg>
    )
  }

  return null
}

export default Icon