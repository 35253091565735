import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Icon from "../components/icon"
import EditCategory from "../components/category-form"
import { request } from '../lib/api'


const Categories = () => {

  const [categories, setCategories] = useState(null)
  const [category, setCategory] = useState(null)

  const getCategories = () => {
    request('GET', `/wwd/categories`).then((e) => {
      const cats = { 'main': [] }

      e.results.forEach((cat) => {
        if (cat.parent === null || cat.parent === undefined) {
          cats['main'].push(cat)

        } else {

          if (cats[cat.parent] === undefined || cats[cat.parent] === null) {
            cats[cat.parent] = []
          }
          cats[cat.parent].push(cat)
        }
        return cats
      })

      setCategories(cats)
    })
  }

  useEffect(()=>{
    if (categories == null) {
      getCategories()
    }
  })

  const onDelete = (categ) => {
    if ( categories[categ._id] !== undefined && categories[categ._id] !== null ) {
      alert(`Oops! A category with subcategories can't be removed`)
      return 
    }
    const data = { ...categ, status: 'deleted' }
    request('POST', '/wwd/categories/update', data).then((e)=>{
      if (!e.success) {
        alert(e.message)
      } else {
        getCategories()
      }
    }).catch((e)=>{
      alert(e.message)
    })
  }

  const onHide = ()=>{
    setCategory(null)
    getCategories()
  }

  return (
    <Layout>
      <SEO title="Categories" />

      <button style={{float: 'right'}} onClick={()=>setCategory({})} className="btn btn-info">
        <Icon name="add" height="15px" color="white" />
      </button>
      <h2>All Categories</h2>
      <div className="row">
        {
          categories != null && categories['main'].map((c, i) => (
            <div className="col-lg-12" key={c._id} >
              <div className="alert alert-info" style={{marginBottom: '0.5rem'}} >

                <strong style={{marginLeft: '5px'}}>{ c.title }</strong>
                <div style={{float: 'right', marginTop: '-8px'}}>
                  {
                    typeof(c.parent) !== 'string' && (
                      <button onClick={()=>setCategory({parent: c._id})} className="btn btn-link">
                        <Icon name="add" height="15px" color="#6c757d" />
                      </button>
                    )
                  }

                  <button onClick={()=>setCategory(c)} className="btn btn-link">
                    <Icon name="edit" height="15px" color="#ffc107" />
                  </button>

                  <button onClick={()=>onDelete(c)} className="btn btn-link">
                    <Icon name="delete" height="15px" color="#dc3545" />
                  </button>
                </div>
              </div>

              <div className="row" style={{ paddingLeft: '2rem' }}>
                {
                  typeof(categories[c._id]) == 'object' && categories[c._id].map((childCat) => (
                    <div className="col-lg-12" key={childCat._id} >
                      <div className="alert alert-info" style={{marginBottom: '0.5rem'}} >
                        <strong style={{marginLeft: '5px'}}>{ childCat.title }</strong>

                        <div style={{float: 'right', marginTop: '-8px'}}>
                          <button onClick={()=>setCategory(childCat)} className="btn btn-link">
                            <Icon name="edit" height="15px" color="#ffc107" />
                          </button>

                          <button onClick={()=>onDelete(childCat)} className="btn btn-link">
                            <Icon name="delete" height="15px" color="#dc3545" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
      <EditCategory category={category} onHide={()=>onHide()} />
    </Layout>
  )
}

export default Categories
