import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import React, { useState } from "react"
import { request } from '../lib/api'

function CategoryForm ({category, ...props}) {

  const [cat, setCat] = useState({})
  const [error, setError] = useState(null)

  const onSave = (data) => {
    setError(null)
    const params = { ...category, ...data }

    const url = typeof(category._id) === 'string' ? '/update' : ''
    request('POST', `/wwd/categories${url}`, params).then((e)=>{
      if (!e.success) {
        setError(e.message)
      } else {
        setError(null)
        props.onHide()
      }
    }).catch((e)=>{
      setError(e.message)
    })
  }

  const isNew = () => {
    if (category === null) { return true }
    return typeof(category._id) !== 'string'
  }

  const getTitle = () => ( isNew() ? '' : category.title )

  return (
    <Modal
      {...props}
      show={category && category !== null}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h5" as="div">
          { isNew() ? 'Add' : 'Edit'} Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { error && <Alert variant={'danger'}>{ error }</Alert>}
        <Form>
          <Form.Group controlId="cat-name">
            <Form.Label>Category Name</Form.Label>
            <Form.Control type="text" placeholder="Name" defaultValue={getTitle()}
              onChange={(e)=>setCat({...cat, title: e.target.value})} />
          </Form.Group>

          <Button style={{ marginTop: '2rem' }} variant="info"
            onClick={() => onSave(cat)} block>
            { isNew() ? 'Create' : 'Update'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CategoryForm